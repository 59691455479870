import { httpHelper, dateHelper } from '../_helpers';

export const cardapioService = {
  obterTodosCardapios,
  gravarCardapio,
  removerCardapio,
  alterarCardapio,
  obterCardapioPorUuid,
  associarTurma,
  associarTurmas,
  obterAssociacaoTurma,
  pesquisarCardapios,
  pesquisaFiltro,
  pesquisaFiltroPaginado,
  removerAssociacaoTurma,
  obterListaCardapiosPaginada,
  obterTodosCardapiosReduzido
};

async function obterTodosCardapios(status = true) {
  return httpHelper.get('/seguro/cardapios/', { params: { status } });
}

async function obterTodosCardapiosReduzido(status = true) {
  return httpHelper.get('/seguro/cardapios/reduzido', { params: { status } });
}

async function obterListaCardapiosPaginada(pagina, tamanhoPagina, status = true) {
  return httpHelper.get('/seguro/cardapios/paginado', {
    params: { status, page: pagina, page_size: tamanhoPagina }
  });
}

async function gravarCardapio(dados) {
  return httpHelper.post('/seguro/cardapios/', { data: dados });
}

async function obterCardapioPorUuid(uuid) {
  return httpHelper.get(`/seguro/cardapios/${uuid}`);
}

async function alterarCardapio(dados) {
  return httpHelper.put(`/seguro/cardapios/`, { data: dados });
}

async function removerCardapio(uuid) {
  return httpHelper.del(`/seguro/cardapios/${uuid}`);
}

async function associarTurma(uuid_turma, cardapios) {
  return httpHelper.put(`/seguro/cardapios/turma/${uuid_turma}/associarCardapio`, {
    data: cardapios
  });
}

async function associarTurmas(uuid_turmas, cardapios) {
  cardapios['uuid_turmas'] = uuid_turmas
  return httpHelper.put(`/seguro/cardapios/turma/associarCardapio`, { data: cardapios});
}

async function obterAssociacaoTurma(turma, data_inicio, data_fim) {
  const dataIni = data_inicio ? dateHelper.formatDate(data_inicio) : null;
  const dataFim = data_fim ? dateHelper.formatDate(data_fim) : null;
  return httpHelper.get(`/seguro/cardapios/associacaoTurma`, {
    params: { turma, data_inicio: dataIni, data_fim: dataFim }
  });
}

async function pesquisarCardapios(uuidsCardapios) {
  return httpHelper.post(`/seguro/cardapios/pesquisa`, {
    data: { uuids: uuidsCardapios }
  });
}

async function pesquisaFiltro(filtro) {
  return httpHelper.post('/seguro/cardapios/pesquisaFiltro', { data: filtro });
}

async function pesquisaFiltroPaginado(filtro, pagina, tamanhoPagina, status = true) {
  return httpHelper.post('/seguro/cardapios/pesquisaFiltro/paginado', {
    data: filtro,
    params: { status, page: pagina, page_size: tamanhoPagina }
  });
}

async function removerAssociacaoTurma(idsAssociacao) {
  return httpHelper.del(`/seguro/cardapios/associacaoTurma`, {
    data: { ids: idsAssociacao }
  });
}
