import React from "react";
import { ACCEPT_MIME_TYPES } from "../components/Constantes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firebaseHelper } from "./firebase.helper";
import { ref, getDownloadURL } from "firebase/storage";

const icone = (tipo, tamanho = "lg", style = {}) => {
  var icon = ACCEPT_MIME_TYPES.filter((mime) => {
    if (tipo.startsWith(mime.type.replace("*", ""))) {
      return mime.icon;
    }
    return null;
  });
  if (icon && icon.length > 0) {
    return (
      <FontAwesomeIcon
        icon={icon[0].icon}
        size={tamanho}
        style={{ ...style }}
      />
    );
  }
  return (
    <FontAwesomeIcon icon={"paperclip"} size={tamanho} style={{ ...style }} />
  );
};

const downloadAnexo = (file) => {
  const storage = firebaseHelper.storage;
  getDownloadURL(ref(storage, file.uri))
    .then((url) => {
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", file.nome);
      link.setAttribute("target", "_blank");

      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    })
    .catch((error) => {
      // Handle any errors
      console.log(error);
    });
};

export const anexoHelper = {
  icone,
  downloadAnexo,
};
