import { createAsyncAction } from 'redux-promise-middleware-actions';
import { painelComunicadosService } from '../_services/painel.comunicados.service';

export const obterFiltros = createAsyncAction('PAINEL_OBTER_FILTROS', async (tipo) => {
  return await painelComunicadosService.obterFiltros(tipo);
});

export const obterIndicadores = createAsyncAction('PAINEL_OBTER_INDICADORES', async (filtro) => {
  return await painelComunicadosService.obterIndicadores(filtro);
});

export const obterComunicadosPorRemetente = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_REMETENTE',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorRemetente(filtro);
  }
);

export const obterComunicadosPorSetor = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_SETOR',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorSetor(filtro);
  }
);

export const obterComunicadosPorTurma = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_TURMA',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorTurma(filtro);
  }
);

export const obterComunicadosPorDestinatario = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_DESTINATARIO',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorDestinatario(filtro);
  }
);

export const obterComunicadosPorSituacao = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_SITUACAO',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorSituacao(filtro);
  }
);

export const obterComunicadosPorStatus = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_STATUS',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorStatus(filtro);
  }
);

export const obterComunicadosPorMesStatus = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_MES_STATUS',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorMesStatus(filtro);
  }
);

export const obterComunicadosPorMesSituacao = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_MES_SITUACAO',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorMesSituacao(filtro);
  }
);

export const obterComunicadosPorAluno = createAsyncAction(
  'PAINEL_OBTER_COMUNICADOS_ALUNO',
  async (filtro) => {
    return await painelComunicadosService.obterComunicadosPorAluno(filtro);
  }
);

export const obterTempoPorDestinatario = createAsyncAction(
  'PAINEL_OBTER_TEMPO_DESTINATARIO',
  async (filtro) => {
    return await painelComunicadosService.obterTempoPorDestinatario(filtro);
  }
);

export const obterDetalheComunicados = createAsyncAction(
  'PAINEL_OBTER_DETALHE_COMUNICADOS',
  async (filtro) => {
    return await painelComunicadosService.obterDetalheComunicados(filtro);
  }
);
