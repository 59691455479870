//import { salvarAluno, obterListaAlunos, removerAluno, obterAluno, alterarAluno } from '../_actions';
import {
  obterIndicadores,
  obterFiltros,
  obterComunicadosPorRemetente,
  obterComunicadosPorSetor,
  obterComunicadosPorTurma,
  obterComunicadosPorDestinatario,
  obterComunicadosPorSituacao,
  obterComunicadosPorStatus,
  obterComunicadosPorMesStatus,
  obterComunicadosPorMesSituacao,
  obterComunicadosPorAluno,
  obterTempoPorDestinatario,
  obterDetalheComunicados
} from '../_actions/painel.comunicados.actions.js';

const initialState = {
  indicadores: {},
  filtros: [],
  comunicadosPorRemetente: {},
  comunicadosPorSetor: {},
  comunicadosPorTurma: {},
  comunicadosPorDestinatario: {},
  comunicadosPorSituacao: {},
  comunicadosPorStatus: {},
  comunicadosPorMesStatus: {},
  comunicadosPorMesSituacao: {},
  comunicadosPorAluno: {},
  tempoPorDestinatario: {},
  detalheComunicados: []
};

//const colors = ['#2B706A', '#3d4381', '#8d5293', '#a46a70', '#b5b185', '#a7c7a4','#c5d8d6'];
const colors = ['#C5D8D6', '#9ebebb', '#85ada9', '#6b9b97', '#518a85', '#45817c', '#2B706A'];
const colors_status = colors //['#4caf50', '#ff9800', '#03a9f4', '#ef5350'];

function getDataChart(data, uniqueDataset,colors) {
  var dataChart = {
    labels: [],
    datasets: [],
    filters: []
  };

  (data.dataSets || []).forEach(function (item, index, array) {
    var bgcolor = colors;

    if (!uniqueDataset) {
      bgcolor = colors[index];
    }

    const values = {
      id: item.id,
      label: item.label,
      backgroundColor: bgcolor,
      //borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      //hoverBorderColor: 'rgba(255,99,132,1)',
      data: item.data
    };

    dataChart.datasets.push(values);
  });

  (data.header || []).forEach(function (item, index, array) {
    if (index < 10) {
      dataChart.labels.push(item.name);
      dataChart.filters.push(item.id);
    }
  });
  return dataChart;
}

function getDataFiltro(filtros) {
  const dadosFiltros = filtros.map((filtro) => ({
    value: filtro.id,
    label: filtro.valor,
    filterLabel: filtro.tipo
  }));

  return dadosFiltros
}


export const painelComunicadosReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(obterIndicadores.fulfilled):
      return { ...state, indicadores: action.payload.data };
    case String(obterDetalheComunicados.fulfilled):
      return { ...state, detalheComunicados: action.payload.data };
    case String(obterFiltros.fulfilled):
      const filtros = action.payload.data;
      return { ...state, filtros: getDataFiltro(filtros) };
      
    case String(obterComunicadosPorRemetente.fulfilled):
      const comunicadosPorRemetente = getDataChart(action.payload.data, false,colors);

      return { ...state, comunicadosPorRemetente: comunicadosPorRemetente };

      case String(obterComunicadosPorSetor.fulfilled):
        const comunicadosPorSetor = getDataChart(action.payload.data, false,colors_status);
  
        return { ...state, comunicadosPorSetor: comunicadosPorSetor };
  
      case String(obterComunicadosPorTurma.fulfilled):
          const comunicadosPorTurma = getDataChart(action.payload.data, false,colors_status);
    
          return { ...state, comunicadosPorTurma: comunicadosPorTurma };
    
        case String(obterComunicadosPorDestinatario.fulfilled):
      const comunicadosPorDestinatario = getDataChart(action.payload.data, false,colors);

      return {
        ...state,
        comunicadosPorDestinatario: comunicadosPorDestinatario
      };

    case String(obterComunicadosPorSituacao.fulfilled):
      const comunicadosPorSituacao = getDataChart(action.payload.data, true,colors);

      return { ...state, comunicadosPorSituacao: comunicadosPorSituacao };

    case String(obterComunicadosPorStatus.fulfilled):
      const comunicadosPorStatus = getDataChart(action.payload.data, true,colors_status);

      return { ...state, comunicadosPorStatus: comunicadosPorStatus };

    case String(obterComunicadosPorMesStatus.fulfilled):
      const comunicadosPorMesStatus = getDataChart(action.payload.data, false,colors_status);

      return { ...state, comunicadosPorMesStatus: comunicadosPorMesStatus };
    
    case String(obterComunicadosPorMesSituacao.fulfilled):
        const comunicadosPorMesSituacao = getDataChart(action.payload.data, false,colors);
  
        return { ...state, comunicadosPorMesSituacao: comunicadosPorMesSituacao };
    
    case String(obterComunicadosPorAluno.fulfilled):
      const comunicadosPorAluno = getDataChart(action.payload.data, false,colors);

      return { ...state, comunicadosPorAluno: comunicadosPorAluno };

    case String(obterTempoPorDestinatario.fulfilled):
      const tempoPorDestinatario = getDataChart(action.payload.data, false,colors);

      return { ...state, tempoPorDestinatario: tempoPorDestinatario };

    default:
      return state;
  }
};
