import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../_actions/notificacao.actions';
import mapMensagensFirebase from '../_constants/firebase.mensagens';

const initialState = {
  notifications: []
};

const errorHandlingNotification = (state, action) => {
  const key = new Date().getTime() + Math.random();
  let message = 'Desculpe, ocorreu um erro ao executar a ação! Favor tente novamente em instantes.';

  if (action.payload.code !== undefined) {
    message = mapMensagensFirebase.get(action.payload.code);
  } else if (!!action.payload && !!action.payload.response) {
    switch (action.payload.response.status) {
      //TODO alinhar os códigos de erro com mensagem personalizada.
      case 400:
        if (action.payload.response.data) {
          // Caso a mensagem tenha uma lista de erros.
          message =
            action.payload.response.data.erros !== undefined
              ? action.payload.response.data.erros
              : action.payload.response.data.message;
        }
        break;
      default:
    }
  }
  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        key,
        message,
        options: {
          key,
          variant: 'error'
        }
      }
    ]
  };
};

export function notificacao(state = initialState, action) {
  const matches = /(.*)_(REJECTED)/.exec(action.type);
  if (matches) return errorHandlingNotification(state, action); //se erro, mostra já a snackbar de erro

  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== action.key)
      };

    default:
      return state;
  }
}
