import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getApp, getApps, initializeApp } from "firebase/app";

var firebaseApp;

// Singleton
if (getApps().length === 0) {
  firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
} else {
  firebaseApp = getApp();
}

const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const firestore = getFirestore(firebaseApp);

if (process.env.REACT_APP_FIREBASE_EMULATOR === "true") {
  connectAuthEmulator(auth, "http://localhost:" + process.env.REACT_APP_FIREBASE_EMULATOR_AUTH_PORT)
  connectStorageEmulator(storage, "localhost", process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT)
  connectFirestoreEmulator(firestore, "localhost", process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT)
}

export const firebaseHelper = {
  firestore,
  storage,
  auth,
};
