import React, { lazy } from "react";
import { Route } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";

import Rotas from "../../_constants/rotas";

import { authHelper } from "../../_helpers";

import { appStyles } from "../../components/styles/AppStyles";

import Menu from "../../components/menu/Menu";

const Home = lazy(() => import("../home/Home"));
const CadastroFuncionario = lazy(() =>
  import("../funcionarios/CadastroFuncionario")
);
const ListaFuncionarios = lazy(() =>
  import("../funcionarios/ListaFuncionarios")
);

const CadastroResponsavel = lazy(() =>
  import("../responsaveis/CadastroResponsavel")
);
const ListaResponsaveis = lazy(() =>
  import("../responsaveis/ListaResponsaveis")
);

const CadastroAluno = lazy(() => import("../alunos/CadastroAluno"));
const ListaAlunos = lazy(() => import("../alunos/ListaAlunos"));
const DesabilitarAlunos = lazy(() => import("../alunos/DesabilitarAlunos"));

const CadastroTurma = lazy(() => import("../turmas/CadastroTurma"));
const ListaTurmas = lazy(() => import("../turmas/ListaTurmas"));
const MigracaoTurma = lazy(() => import("../turmas/MigracaoTurma"));

const ListaAusencias = lazy(() => import("../ausencias/ListaAusencias"));

const CadastroDisciplina = lazy(() =>
  import("../disciplinas/CadastroDisciplina")
);
const ListaDisciplinas = lazy(() => import("../disciplinas/ListaDisciplinas"));

const CadastroSetor = lazy(() => import("../setor/CadastroSetor"));
const ListaSetores = lazy(() => import("../setor/ListaSetores"));

const PainelMensagens = lazy(() => import("../dashboard/PainelMensagens"));
const PainelMensagensChartJ = lazy(() =>
  import("../dashboard/PainelMensagens.ChartJ")
);
const PainelDiario = lazy(() => import("../dashboard/PainelDiario"));
const PainelDiarioChartJ = lazy(() =>
  import("../dashboard/PainelDiario.ChartJ")
);

const RevisaoMensagens = lazy(() =>
  import("../mensagem/revisao/RevisaoMensagens")
);
const EnvioMensagem = lazy(() => import("../mensagem/EnvioMensagem"));

const CadastrarAlterarEventos = lazy(() =>
  import("../eventos/CadastrarAlterarEventos")
);
const ListaEventos = lazy(() => import("../eventos/ListaEventos"));

const CadastroCardapios = lazy(() => import("../cardapios/CadastroCardapios"));
const ListaCardapios = lazy(() => import("../cardapios/ListaCardapios"));
const PlanejarCardapios = lazy(() => import("../cardapios/PlanejarCardapios"));

const CadastroDiario = lazy(() => import("../diario/CadastroRotinaDiaria"));
const ListaDiario = lazy(() => import("../diario/ListaRotinaDiaria"));

const CadastroProfessor = lazy(() =>
  import("../professores/CadastroProfessor")
);
const ListaProfessores = lazy(() => import("../professores/ListaProfessores"));
const ListaAlbums = lazy(() => import("../album/ListaAlbuns"));
const Galeria = lazy(() => import("../album/Galeria"));
const ManterIngrediente = lazy(() =>
  import("../ingredientes/ManterIngredientes")
);
const CadastroInformativo = lazy(() =>
  import("../informativo/CadastroInformativo")
);
const ListaInformativos = lazy(() =>
  import("../informativo/ListaInformativos")
);

const Configuracoes = lazy(() => import("../configuracoes/Configuracoes"));

const Comunicacao = lazy(() => import("../comunicacao/Comunicacao"));

const ListaComunicadosv2 = lazy(() =>
  import("../comunicadosv2/CaixaComunicados")
);
const Comunicadov2 = lazy(() =>
  import("../comunicadosv2/EmailContent/EmailContent")
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showWarningMessage: !authHelper.isTokenFresh() };
  }

  render() {
    return (
      <>
        <CssBaseline />
        <Menu>
          <Route exact path={Rotas.home} component={Home} />

          <Route
            exact
            path={Rotas.funcionario.cadastrar}
            component={CadastroFuncionario}
          />
          <Route
            exact
            path={Rotas.funcionario.alterar}
            component={CadastroFuncionario}
          />
          <Route
            exact
            path={Rotas.funcionario.listar}
            component={ListaFuncionarios}
          />

          <Route
            exact
            path={Rotas.responsavel.cadastrar}
            component={CadastroResponsavel}
          />
          <Route
            exact
            path={Rotas.responsavel.alterar}
            component={CadastroResponsavel}
          />
          <Route
            exact
            path={Rotas.responsavel.listar}
            component={ListaResponsaveis}
          />

          <Route exact path={Rotas.aluno.cadastrar} component={CadastroAluno} />
          <Route exact path={Rotas.aluno.alterar} component={CadastroAluno} />
          <Route exact path={Rotas.aluno.listar} component={ListaAlunos} />

          <Route
            exact
            path={Rotas.aluno.desabilitar}
            component={DesabilitarAlunos}
          />

          <Route
            exact
            path={Rotas.ausencia.listar}
            component={ListaAusencias}
          />

          <Route exact path={Rotas.turma.cadastrar} component={CadastroTurma} />
          <Route exact path={Rotas.turma.migracao} component={MigracaoTurma} />

          <Route exact path={Rotas.turma.alterar} component={CadastroTurma} />
          <Route exact path={Rotas.turma.listar} component={ListaTurmas} />

          <Route
            exact
            path={Rotas.disciplina.cadastrar}
            component={CadastroDisciplina}
          />
          <Route
            exact
            path={Rotas.disciplina.alterar}
            component={CadastroDisciplina}
          />
          <Route
            exact
            path={Rotas.disciplina.listar}
            component={ListaDisciplinas}
          />

          <Route exact path={Rotas.setor.cadastrar} component={CadastroSetor} />
          <Route exact path={Rotas.setor.alterar} component={CadastroSetor} />
          <Route exact path={Rotas.setor.listar} component={ListaSetores} />

          <Route
            exact
            path={Rotas.painel.comunicados}
            component={PainelMensagensChartJ}
          />
      
          <Route
            exact
            path={Rotas.evento.cadastrar}
            component={CadastrarAlterarEventos}
          />
          <Route
            exact
            path={Rotas.evento.alterar}
            component={CadastrarAlterarEventos}
          />
          <Route exact path={Rotas.evento.listar} component={ListaEventos} />

          <Route exact path={Rotas.caixa.envio} component={EnvioMensagem} />
          <Route
            exact
            path={Rotas.caixa.revisao}
            component={RevisaoMensagens}
          />

          <Route
            exact
            path={Rotas.cardapio.cadastrar}
            component={CadastroCardapios}
          />
          <Route
            exact
            path={Rotas.cardapio.alterar}
            component={CadastroCardapios}
          />
          <Route
            exact
            path={Rotas.cardapio.listar}
            component={ListaCardapios}
          />
          <Route
            exact
            path={Rotas.cardapio.planejar}
            component={PlanejarCardapios}
          />
          <Route
            exact
            path={Rotas.rotina.cadastrar}
            component={CadastroDiario}
          />
          <Route exact path={Rotas.rotina.alterar} component={CadastroDiario} />
          <Route exact path={Rotas.rotina.listar} component={ListaDiario} />
          <Route
            exact
            path={Rotas.professor.cadastrar}
            component={CadastroProfessor}
          />
          <Route
            exact
            path={Rotas.professor.listar}
            component={ListaProfessores}
          />
          <Route
            exact
            path={Rotas.professor.alterar}
            component={CadastroProfessor}
          />
          <Route exact path={Rotas.album.listar} component={ListaAlbums} />
          <Route exact path={Rotas.album.galeria} component={Galeria} />
          <Route
            exact
            path={Rotas.ingredientes.manter}
            component={ManterIngrediente}
          />

          <Route
            exact
            path={Rotas.informativo.cadastrar}
            component={CadastroInformativo}
          />
          <Route
            exact
            path={Rotas.informativo.alterar}
            component={CadastroInformativo}
          />
          <Route
            exact
            path={Rotas.informativo.listar}
            component={ListaInformativos}
          />
          <Route
            exact
            path={Rotas.configuracoes.geral}
            component={Configuracoes}
          />
          <Route exact path={Rotas.comunicacao} component={Comunicacao} />
          <Route
            exact
            path={Rotas.caixa.lista}
            component={ListaComunicadosv2}
          />
          <Route
            exact
            path={Rotas.caixa.detalhamento}
            component={Comunicadov2}
          />
        </Menu>
      </>
    );
  }
}

export default withStyles(appStyles)(App);
