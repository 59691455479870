import { createAsyncAction } from 'redux-promise-middleware-actions';
import { dominiosService } from '../_services';

export const obterListaFuncionalidades = createAsyncAction('FUNCIONALIDADES_OBTER', async () => {
  return await dominiosService.obterListaFuncionalidades();
});

export const obterListaFuncionalidadesInformativo = createAsyncAction(
  'FUNCIONALIDADES_INFORMATIVO_OBTER',
  async () => {
    return await dominiosService.obterListaFuncionalidadesInformativo();
  }
);

export const obterListaTiposUsuario = createAsyncAction('TIPOS_USUARIO_OBTER', async () => {
  return await dominiosService.obterTiposUsuario();
});

export const obterListaTiposPeriodo = createAsyncAction('TIPOS_PERIODO_OBTER', async () => {
  return await dominiosService.obterTiposPeriodo();
});

export const obterGrausEscolares = createAsyncAction('GRAUS_ESCOLARES_OBTER', async () => {
  return await dominiosService.obterGrausEscolares();
});

export const obterUnidadesFederativas = createAsyncAction('UFS_OBTER', async () => {
  return await dominiosService.obterUnidadesFederativas();
});

export const obterMunicipiosPorSiglaUF = createAsyncAction(
  'MUNICIPIOS_SIGLA_UF_OBTER',
  async (sigla) => {
    return await dominiosService.obterMunicipiosPorSiglaUF(sigla);
  }
);

export const obterMunicipiosPorCodigoUF = createAsyncAction(
  'MUNICIPIOS_CODIGO_UF_OBTER',
  async (codigo) => {
    return await dominiosService.obterMunicipiosPorCodigoUF(codigo);
  }
);

export const obterMunicipioPorCodigo = createAsyncAction(
  'MUNICIPIO_CODIGO_OBTER',
  async (codigo) => {
    return await dominiosService.obterMunicipioPorCodigo(codigo);
  }
);

export const obterListaGruposAlimentares = createAsyncAction(
  'TIPOS_GRUPOS_ALIMENTARES_OBTER',
  async () => {
    return await dominiosService.obterGruposAlimentares();
  }
);

export const obterListaTipoFrequencia = createAsyncAction('LISTA_FREQUENCIA_OBTER', async () => {
  return await dominiosService.obterTiposFrequencia();
});

export const obterFrequenciaAvaliacoes = createAsyncAction('LISTA_FREQUENCIA_AVALIACOES', async () => {
  return await dominiosService.obterFrequenciaAvaliacoes();
});

export const obterPeriodoAvaliacoes = createAsyncAction('LISTA_PERIODO_AVALIACOES', async () => {
  return await dominiosService.obterPeriodoAvaliacoes();
});

export const limpaListaMunicipios = () => {
  return {
    type: 'LIMPA_LISTA_MUNICIPIOS'
  };
};
