// import config from 'config';
import { httpHelper } from '../_helpers';

export const dominiosService = {
  obterListaFuncionalidades,
  obterListaFuncionalidadesInformativo,
  obterTiposPeriodo,
  obterTiposUsuario,
  obterGrausEscolares,
  obterUnidadesFederativas,
  obterMunicipiosPorSiglaUF,
  obterMunicipiosPorCodigoUF,
  obterMunicipioPorCodigo,
  obterGruposAlimentares,
  obterTiposFrequencia,
  obterFrequenciaAvaliacoes,
  obterPeriodoAvaliacoes
};

function obterListaFuncionalidades() {
  return httpHelper.get('/seguro/dominios/funcionalidades?sistema=RETAGUARDA');
}

function obterListaFuncionalidadesInformativo() {
  return httpHelper.get('/seguro/dominios/funcionalidadesinformativo?sistema=RETAGUARDA');
}

function obterTiposPeriodo() {
  return httpHelper.get('/seguro/dominios/periodos');
}

function obterTiposUsuario() {
  return httpHelper.get('/seguro/dominios/usuarios');
}

function obterGrausEscolares() {
  return httpHelper.get('/seguro/dominios/grauescolar');
}

function obterUnidadesFederativas() {
  return httpHelper.get('/seguro/dominios/uf');
}

function obterMunicipiosPorSiglaUF(sigla) {
  return httpHelper.get(`/seguro/dominios/municipios/uf/${sigla}`);
}

function obterMunicipiosPorCodigoUF(codigo) {
  return httpHelper.get(`/seguro/dominios/municipios/uf/${codigo}`);
}

function obterMunicipioPorCodigo(codigo) {
  return httpHelper.get(`/seguro/dominios/municipios/${codigo}`);
}

function obterGruposAlimentares() {
  return httpHelper.get('/seguro/dominios/grupoalimentar');
}

function obterTiposFrequencia() {
  return httpHelper.get('/seguro/dominios/tipofrequencia');
}

function obterFrequenciaAvaliacoes() {
  return httpHelper.get('/seguro/dominios/frequencias_avaliacao');
}

function obterPeriodoAvaliacoes() {
  return httpHelper.get('/seguro/dominios/periodo_avaliacao');
}