import {
  obterListaTiposUsuario,
  obterListaTiposPeriodo,
  obterListaGruposAlimentares,
  obterGrausEscolares,
  obterUnidadesFederativas,
  obterMunicipiosPorSiglaUF,
  obterMunicipiosPorCodigoUF,
  obterMunicipioPorCodigo,
  obterFrequenciaAvaliacoes,
  obterPeriodoAvaliacoes
} from '../_actions';

const initialState = {
  setores: [],
  tiposUsuario: [],
  tiposPeriodo: [],
  grausEscolares: [],
  ufs: [],
  municipios: [],
  municipio: {},
  gruposAlimentares: [],
  frequenciaAvaliacoes: [],
  periodosAvaliacoes: []
};

const converteDominio = (lista, value = 'id', label = 'descricao') => {
  let retorno = [];
  if (lista) {
    lista.forEach((item) => {
      retorno.push({ label: item[label], value: item[value] });
    });
  }
  return [...retorno];
};

export const dominiosReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(obterListaTiposUsuario.fulfilled):
      return { ...state, tiposUsuario: converteDominio(action.payload.data) };
    case String(obterListaTiposPeriodo.fulfilled):
      return { ...state, tiposPeriodo: converteDominio(action.payload.data) };
    case String(obterGrausEscolares.fulfilled):
      return { ...state, grausEscolares: converteDominio(action.payload.data) };
    case String(obterUnidadesFederativas.fulfilled):
      return {
        ...state,
        ufs: converteDominio(action.payload.data, 'codigo', 'nome')
      };
    case String(obterMunicipiosPorSiglaUF.fulfilled):
      return {
        ...state,
        municipios: converteDominio(action.payload.data, 'codigo', 'nome')
      };
    case String(obterMunicipiosPorCodigoUF.fulfilled):
      return {
        ...state,
        municipios: converteDominio(action.payload.data, 'codigo', 'nome')
      };
    case String(obterMunicipioPorCodigo.fulfilled):
      return { ...state, municipio: action.payload.data };
    case String(obterListaGruposAlimentares.fulfilled):
      return {
        ...state,
        gruposAlimentares: converteDominio(action.payload.data)
      };
    case String(obterFrequenciaAvaliacoes.fulfilled):
      return {
        ...state,
        frequenciaAvaliacoes: converteDominio(action.payload.data)
      };
    case String(obterPeriodoAvaliacoes.fulfilled):
        return {
          ...state,
          periodosAvaliacoes: converteDominio(action.payload.data)
        };
    case String('LIMPA_LISTA_MUNICIPIOS'):
      return { ...state, municipios: [] };
    default:
      return state;
  }
};
